import React from "react";

export default function LoadingComponent() {
  return (
    <div className="flex  justify-center items-center w-[80vw] h-[40vh] bg-gray-100">
      <div className="text-center">
        <div className="w-16 h-16 border-4 border-[#D29A92] border-t-transparent rounded-full animate-spin mx-auto mb-4"></div>
        <h2 className="text-lg font-semibold text-[#D29A92]">Cargando...</h2>
      </div>
    </div>
  );
}
