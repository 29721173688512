export default function SliderComponent({
  carouselImages,
  currentSlide,
  handlePrevSlide,
  handleNextSlide,
}) {
  return (
    <section className="my-8 mt-24 flex flex-col items-center">
      <div className="relative w-full lg:max-w-4xl xl:max-w-6xl h-72 md:h-[28rem] lg:h-[36rem] bg-gray-200 overflow-hidden rounded-lg shadow-lg">
        <img
          src={carouselImages[currentSlide]}
          alt={`Slide ${currentSlide + 1}`}
          className="w-full h-full object-cover object-center"
          style={{ filter: "drop-shadow(2px 2px 4px rgba(0, 0, 0, 0.5))" }}
        />
        <button
          onClick={handlePrevSlide}
          className="absolute top-1/2 left-4 transform -translate-y-1/2 bg-white rounded-full p-2 md:p-3 lg:p-4 shadow-md"
          style={{ filter: "drop-shadow(2px 2px 4px rgba(0, 0, 0, 0.5))" }}
        >
          <svg
            style={{ color: "#D29A92" }}
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="24"
            viewBox="0 0 24 24"
          >
            <path
              fill="currentColor"
              d="M15.41 16.58L10.83 12l4.58-4.59L14 6l-6 6l6 6z"
            />
          </svg>
        </button>
        <button
          onClick={handleNextSlide}
          className="absolute top-1/2 right-4 transform -translate-y-1/2 bg-white rounded-full p-2 md:p-3 lg:p-4 shadow-md"
          style={{ filter: "drop-shadow(2px 2px 4px rgba(0, 0, 0, 0.5))" }}
        >
          <svg
            style={{ color: "#D29A92" }}
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="24"
            viewBox="0 0 24 24"
          >
            <path
              fill="currentColor"
              d="M8.59 16.58L13.17 12L8.59 7.41L10 6l6 6l-6 6z"
            />
          </svg>
        </button>
      </div>
    </section>
  );
}
