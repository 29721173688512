export default function ContainerComponent({stringWeb}) {

    return(
        <div className="mx-4 my-8 max-w-5xl mx-auto bg-white rounded-lg shadow-lg p-6 border">
        <h2 className="text-3xl font-bold text-[#D29A92] mb-4 text-center">
          {stringWeb[0]?.title || "Bienvenidos a VF, tu centro de alta estética medicinal"}
        </h2>
        <p className="text-[#9D736D] leading-relaxed text-lg text-center">
        {stringWeb[0]?.container || "En VF, ofrecemos tratamientos avanzados de rejuvenecimiento facial y corporal con resultados efectivos y duraderos. Con profesionales altamente capacitados, utilizamos tecnología de punta en botox, rellenos de ácido hialurónico, lifting sin cirugía, y más, para asegurar un servicio de calidad en un ambiente seguro y de confianza."}          
        </p>
        <br></br>
        <p className="text-[#9D736D] leading-relaxed text-lg text-center">
        {stringWeb[0]?.container2 || "En VF, ofrecemos tratamientos avanzados de rejuvenecimiento facial y corporal con resultados efectivos y duraderos. Con profesionales altamente capacitados, utilizamos tecnología de punta en botox, rellenos de ácido hialurónico, lifting sin cirugía, y más, para asegurar un servicio de calidad en un ambiente seguro y de confianza."}          
        </p>
      </div>
    )
}