import { useEffect, useState } from "react";

import { getDocumentsFirebase } from "../services/data-firebase";
export default function CalendarComponent({ selectedDate, setSelectedDate }) {
  const [currentMonth, setCurrentMonth] = useState(new Date().getMonth());
  const [currentYear, setCurrentYear] = useState(new Date().getFullYear());
  const [allCitas, setAllCitas] = useState([]);
  const [maxFechaPorDia, setMaxFechaPorDia] = useState(3);

  const monthNames = [
    "Enero", "Febrero", "Marzo", "Abril", "Mayo", "Junio", 
    "Julio", "Agosto", "Septiembre", "Octubre", "Noviembre", "Diciembre"
  ];

  useEffect(() => {
    async function getFechasCitas() {
      try {
        const getAllFechasCitasFireBase = await getDocumentsFirebase("citas");
        setAllCitas(getAllFechasCitasFireBase);
        
        const getMaxClientWithDay = await getDocumentsFirebase('clients-whit-days');
        if (getMaxClientWithDay.length > 0) {
          setMaxFechaPorDia(getMaxClientWithDay[0].clientsCount);
        }
        
      } catch (error) {
        console.log(error);
        throw error;
      }
    }
    getFechasCitas();
  }, [selectedDate]);

  const prevMonth = () => {
    if (currentMonth === 0) {
      setCurrentMonth(11);
      setCurrentYear(currentYear - 1);
    } else {
      setCurrentMonth(currentMonth - 1);
    }
  };

  const nextMonth = () => {
    if (currentMonth === 11) {
      setCurrentMonth(0);
      setCurrentYear(currentYear + 1);
    } else {
      setCurrentMonth(currentMonth + 1);
    }
  };

  const getDaysInMonth = (month, year) => new Date(year, month + 1, 0).getDate();
  const daysInMonth = getDaysInMonth(currentMonth, currentYear);
  const getFirstDayOfMonth = (month, year) => new Date(year, month, 1).getDay();

  const hasMaxCitas = (day) => {
    const dayString = `${day}/${currentMonth + 1}/${currentYear}`;
    const citasPorDia = allCitas.filter(cita => cita.fecha === dayString);
    return citasPorDia.length >= maxFechaPorDia;
  };

  const handleDateClick = (day) => {
    if (day !== null && !hasMaxCitas(day + 1)) {
      const selectedDateString = `${day + 1}/${currentMonth + 1}/${currentYear}`;
      setSelectedDate(selectedDateString);
      console.log("Fecha seleccionada:", selectedDateString);
    }
  };

  return (
    <>
      {!selectedDate && (
        <div className="w-full max-w-sm mx-auto font-sans border border-gray-300 rounded-lg shadow-lg overflow-hidden">
          <div className="flex justify-between items-center bg-gray-100 p-3">
            <button onClick={prevMonth} className="bg-blue-500 text-white px-3 py-1 text-lg cursor-pointer rounded hover:bg-blue-700">{"<"}</button>
            <span>{monthNames[currentMonth]} {currentYear}</span>
            <button onClick={nextMonth} className="bg-blue-500 text-white px-3 py-1 text-lg cursor-pointer rounded hover:bg-blue-700">{">"}</button>
          </div>
          <div className="p-3">
            <div className="grid grid-cols-7 gap-1 text-center font-bold text-gray-600">
              {["Dom", "Lun", "Mar", "Mié", "Jue", "Vie", "Sáb"].map(day => (
                <div key={day}>{day}</div>
              ))}
            </div>
            <div className="grid grid-cols-7 gap-1 mt-2">
              {[...Array(getFirstDayOfMonth(currentMonth, currentYear)).fill(null), ...Array(daysInMonth).keys()].map((day, index) => (
                <div
                  key={index}
                  className={`text-center p-2 rounded cursor-pointer ${day === null ? "" : "bg-gray-200"} ${day !== null && hasMaxCitas(day + 1) ? "bg-yellow-500 text-white font-bold rounded-full" : ""}`}
                  onClick={() => handleDateClick(day)}
                >
                  {day !== null && day + 1}
                </div>
              ))}
            </div>
          </div>
          {selectedDate && <p>Fecha seleccionada: {selectedDate}</p>}
        </div>
      )}
    </>
  );
}
