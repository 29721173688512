import React from "react";

const MapComponent = () => {


  return (
    <div className="map-container p-4">
    <h2 className="text-2xl font-bold text-[#D29A92] mb-4">Ubicación</h2>
    <iframe
      title="Ubicación de la Estética"
      src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d1000!2d-69.229644!3d-51.614910!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0x0!2z!5e0!3m2!1ses-419!2sar!4v1635976846571!5m2!1ses-419!2sar"
      style={{ width: "100%", height: "450px", border: "0" }}
      allowFullScreen=""
      loading="lazy"
    ></iframe>
    
    <a
    href="https://www.google.com/maps/dir/?api=1&destination=-51.614910,-69.229644"
    target="_blank"
    rel="noopener noreferrer"
    className="mt-4 flex items-center  justify-center inline-block text-[#D29A92] hover:underline"
  >
Cómo llegar a nuestra estética  </a>
  </div>
  
  );
};

export default MapComponent;
