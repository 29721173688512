import { useEffect, useState } from "react";

import imgBotox from "../assets/botox/botox1.jpg";
import acidoRelleno from "../assets/acido/acido.png";
import lipo from "../assets/liposuccion/liposuccion.webp";
import masoCapilar from "../assets/mesoterapiaCapilar/capilar.jpg";
import masoCorporal from "../assets/mesoterapiaCorporal/masoCorporal.jpg";
import LoadingComponent from "./LoadingComponent";

export default function ServicesComponent({ allServicesEsteticFV }) {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedService, setSelectedService] = useState(null);
  const [currentImageIndex, setCurrentImageIndex] = useState(0);

  const serviceImages = [imgBotox, acidoRelleno, masoCapilar, masoCorporal, lipo];

  const [isData, setIsData] = useState(false);
    
  useEffect(()=>{
    setIsData(true)
    setTimeout(() => {
      setIsData(false)
    }, 1800);
  },[])
  

  const handleSeeMore = (service) => {
    setSelectedService(service);
    setIsModalOpen(true);
    setCurrentImageIndex(0);
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
    setSelectedService(null);
    setCurrentImageIndex(0);
  };

  const handleNextImage = () => {
  
    if (selectedService && selectedService.imgUrl) {
      setCurrentImageIndex((prevIndex) =>
        prevIndex === selectedService.imgUrl.length - 1 ? 0 : prevIndex + 1
      );
    }
  };

  const handlePrevImage = () => {
    if (selectedService && selectedService.imgUrl) {
      setCurrentImageIndex((prevIndex) =>
        prevIndex === 0 ? selectedService.imgUrl.length - 1 : prevIndex - 1
      );
    }
  };
  
/*   const handleImageLoad = () => {
    setIsData(true); // Oculta el loader cuando la imagen se ha cargado
  }; */

  return (
    <div id="servicios" className="mx-4 my-8 max-w-5xl mx-auto bg-white rounded-lg shadow-lg p-6">
      <h2 className="text-2xl font-semibold text-[#D29A92] mb-4 text-center">
        Servicios
      </h2>

      {allServicesEsteticFV[0]?.["services-vf"]?.map((serviceFv, index) => (
       <div
       key={serviceFv.name}
       className="relative mb-4 transition-transform duration-200 hover:scale-105 overflow-hidden rounded-lg shadow-md sm:mb-6 md:mb-8 lg:mb-10"
     >
       <img
         src={serviceImages[index]}
         alt={serviceFv.name}
         className="w-full h-48 sm:h-56 md:h-64  lg:h-72 object-cover rounded-lg"
       />
       <button
         onClick={() => handleSeeMore(serviceFv)}
         className="absolute inset-0 flex items-center justify-center text-white font-semibold bg-gray-500 bg-opacity-10 hover:bg-opacity-70"
       >
         <p className="mt-20 text-sm sm:text-base md:text-lg lg:text-xl">{serviceFv.name}</p>
       </button>
     </div>
     
      ))}

      {/* Modal */}
      {isModalOpen && selectedService && (
     /*    <div className="fixed inset-0 z-50 flex items-center justify-center bg-black bg-opacity-50 overflow-y-auto"> */
        <div className="fixed inset-0 z-50 flex items-center justify-center bg-black bg-opacity-50 overflow-y-auto">
          <div className="bg-white flex flex-col items-center rounded-lg shadow-lg max-w-md w-full p-6 relative overflow-y-auto max-h-[90vh]">
            {/* Botón de cierre */}
            <button
              onClick={handleCloseModal}
              className="absolute top-4 right-4 text-gray-500 hover:text-gray-700"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                className="h-6 w-6"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
                
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                  d="M6 18L18 6M6 6l12 12"
                />
              </svg>
            </button>

            {/* Título del servicio */}
            <h2 className="text-2xl font-semibold text-[#D29A92] md:mt-1  mb-4 text-center">
              {selectedService.name}
            </h2>

            {/* Slider de imágenes */}
            {selectedService.imgUrl && (
              <div className="relative w-full max-w-md flex justify-center items-center mb-4">
                <button
                  onClick={handlePrevImage}
                  className="absolute left-2 top-1/2 transform -translate-y-1/2 bg-gray-200 bg-opacity-40 p-2 rounded-full"
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    style={{color:"#D29A92"}}
                  >
                    <path fill="currentColor" d="M15.41 16.58L10.83 12l4.58-4.59L14 6l-6 6l6 6z" />
                  </svg>
                </button>
                {isData ? (
      <LoadingComponent />
    ) : (
      <img
        src={selectedService.imgUrl[currentImageIndex]}
        alt={selectedService.name}
        className="w-full h-60 md:max-h-[90vh] object-cover rounded-lg"
/*         onLoad={handleImageLoad} // Llama a handleImageLoad cuando la imagen se cargue */
      />
    )}
               
                <button
                  onClick={handleNextImage}
                  className="absolute right-2 top-1/2 transform -translate-y-1/2 bg-gray-200 bg-opacity-40 p-2 rounded-full"
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="24"
                    height="24"
                    style={{color:"#D29A92"}}
                    viewBox="0 0 24 24"
                  >
                    <path fill="currentColor" d="M8.59 16.58L13.17 12L8.59 7.41L10 6l6 6l-6 6z" />
                  </svg>
                </button>
              </div>
            )}

            {/* Descripción del servicio */}
            <div className="text-blue-gray-700 space-y-2 text-center px-4">
              {selectedService.detail ? (
                selectedService.detail.map((info, index) => (
                  <p key={index} className="leading-relaxed text-[#694D49]">
                    {info}
                  </p>
                ))
              ) : (
                <p>No hay información adicional disponible.</p>
              )}
            </div>
          </div>
        </div>
      )}
    </div>
  );
}
