import React, { useState } from "react";

const FAQComponent = () => {
  const faqs = [
    {
      question: "¿Cuánto tiempo dura el tratamiento?",
      answer: "La duración del tratamiento varía entre 30 y 60 minutos dependiendo del tipo de servicio.",
    },
    {
      question: "¿Cuánto tiempo de reposo se necesita?",
      answer: "En la mayoría de los casos, no se requiere tiempo de reposo, pero para algunos tratamientos específicos, se recomienda un reposo de 24 horas.",
    },
    {
      question: "¿El tratamiento es doloroso?",
      answer: "La mayoría de los tratamientos son indoloros. Algunos pueden generar una ligera incomodidad dependiendo de la sensibilidad de cada persona.",
    },
    {
      question: "¿Cuáles son los cuidados posteriores al tratamiento?",
      answer: "Se recomienda evitar la exposición al sol, usar protector solar y mantener hidratada la piel para obtener mejores resultados.",
    },
    {
      question: "¿Es necesario hacer una consulta previa?",
      answer: "Sí, es importante realizar una consulta previa para evaluar tus necesidades y determinar el mejor tratamiento para ti.",
    },
    {
      question: "¿Con qué frecuencia debo realizarme el tratamiento?",
      answer: "La frecuencia varía según el tratamiento y tus objetivos. Normalmente se recomienda realizar sesiones mensuales o bimensuales.",
    },
    {
      question: "¿Hay contraindicaciones para el tratamiento?",
      answer: "Sí, algunos tratamientos no están recomendados para mujeres embarazadas, personas con ciertas condiciones médicas o alergias. Consulta con nuestros especialistas.",
    },
    {
      question: "¿Se pueden combinar diferentes tratamientos en una misma sesión?",
      answer: "Sí, muchos tratamientos son complementarios y se pueden realizar juntos, dependiendo de tus necesidades y recomendaciones del especialista.",
    },
    {
      question: "¿Cuánto tiempo tardan en verse los resultados?",
      answer: "Los resultados pueden ser visibles inmediatamente o después de unos días, dependiendo del tratamiento específico.",
    },
    {
      question: "¿Qué productos se utilizan durante los tratamientos?",
      answer: "Utilizamos productos de alta calidad, clínicamente aprobados y adaptados a las necesidades de cada cliente.",
    },
  ];

  const [activeIndex, setActiveIndex] = useState(null);

  const toggleFAQ = (index) => {
    setActiveIndex(activeIndex === index ? null : index);
  };

  return (
    <div className="faq-container p-4 flex flex-col items-center justify-center">
      <h2 className="text-2xl font-bold mb-4 text-[#D29A92] text-center">
        Preguntas Frecuentes
      </h2>
      <div className="faq-list w-full max-w-2xl">
        {faqs.map((faq, index) => (
          <div
            key={index}
            className="faq-item border-b py-4 cursor-pointer text-center"
            onClick={() => toggleFAQ(index)}
          >
            <div className="question text-lg text-[#9D736D] font-semibold">
              {faq.question}
            </div>
            {activeIndex === index && (
              <div className="answer mt-2 text-[#694D49] text-gray-600">
                {faq.answer}
              </div>
            )}
          </div>
        ))}
      </div>
    </div>
  );
};

export default FAQComponent;
